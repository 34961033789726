import './App.css';
import { useEffect, useState } from 'react';
import { SubscriberPortfolioService } from './services/SubscriberPortfolioService';
import LoadingHomePage from './pages/LoadingHomePage/LoadingHomePage';
import PortfolioViewerPage from './pages/PortfolioViewerPage/PortfolioViewerPage';
import SnapshotViewerPage from './pages/SnapshotViewerPage/SnapshotViewerPage';
import FPNotificationsPage from './pages/FPNotificationsPage/FPNotificationsPage';

function App() {

  const [initData, setInitData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [subscriberPortfolio, setSubscriberPortfolio] = useState(null);
  const [webApp, setWebApp] = useState(null);
  const [userData, setUserData] = useState(null);
  const [searchParams, setSearchParams] = useState(new URLSearchParams(window.location.search));
  const [action, setAction] = useState(null);

  const waitSeconds = (seconds) => {
    return new Promise((resolve, reject) => {

      setTimeout(() => {
        resolve();
      }, 1000 * seconds);

    });
  }

  const handleUserData = (initData) => {

    if (typeof initData === 'string') {

      try {

        const encoded = decodeURIComponent(initData);

        // Data-check-string is a chain of all received fields'.
        const arr = encoded.split('&');
        const initDataJSON = {};
        for (const item of arr) {
          initDataJSON[item.split('=')[0]] = item.split('=')[1];
        }
        initData = initDataJSON;

      } catch (error) {

        throw 'Failed to parse initData: ' + error;

      }

    }

    if (initData.user) {

      console.log('Parsed userData:', initData);

      initData.user = typeof initData.user === 'string' ? JSON.parse(initData.user) : initData.user;

      setUserData(initData);

    }

  }

  useEffect(() => {

    const findTelegramWebApp = async (retries) => {

      retries = isNaN(retries) ? 0 : retries;

      const errorMessage = "You need to open this page with Telegram App!";

      if (retries > 10) {

        setLoading(false);
        alert(errorMessage);
        window.close();
        return;

      }

      if (window?.Telegram?.WebApp.initData) {

        const WebApp = window?.Telegram?.WebApp;

        console.log(`window?.Telegram?.WebApp? `, WebApp);
        setWebApp(WebApp);
        const inInitData = WebApp.initData
        console.log('initData: ', inInitData);
        console.log(typeof inInitData);
        setInitData(inInitData);
        const subPortfolio = await SubscriberPortfolioService.getSubscriberPortfolioData(inInitData);
        setSubscriberPortfolio(subPortfolio);
        handleUserData(inInitData);
        setLoading(false);
        return;
        
      } else {
        
        console.error(errorMessage);
        retries += 1;
        await waitSeconds(1);
        await findTelegramWebApp(retries);

      }

    }

    findTelegramWebApp();

  }, []);

  useEffect(() => {
    console.log('searchParams: ', searchParams);
    const getAction = () => {
      const selAction = searchParams.get('action');
      setAction(selAction);
    }
    getAction();
  }, [searchParams]);

  useEffect(() => {
    console.log('action: ', action);
  }, [action]);

  useEffect(() => {

  }, [userData]);

  // useEffect(() => {

  //   console.log('subscriberPortfolio: ', subscriberPortfolio);

  // }, [subscriberPortfolio]);



  if (loading) {

    return (
      <LoadingHomePage userData={userData} />
    );

  } else {

    if (webApp == null) {
      return (
        <div className="App">
          <header className="App-header">
            <p>
              You need to open this page with Telegram App!
            </p>
          </header>
        </div>
      );

    } else {

      if (action == null) {
        return (
          <PortfolioViewerPage userData={userData} subscriberPortfolio={subscriberPortfolio} setSubscriberPortfolio={setSubscriberPortfolio} />
        );
      } else if (action == 'snapshot-viewer') {
        return (
          <SnapshotViewerPage userData={userData} />
        )
      } else if (action == 'fp-notifications') {
        return (
          <FPNotificationsPage userData={userData} initData={initData} />
        )

      }

      // return (
      //   <LoadingHomePage userData={userData} />
      // );

    }

  }

}

export default App;
