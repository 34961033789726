// const { convertDateToDatabaseFormat } = require("./DateUtil");

const MAGICEDENURL = process.env.REACT_APP_MAGICEDENURL;

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

class CollectionDailyInfo {

    collection_symbol
    total_volume
    owners
    supply
    floor_price
    total_listed
    pending_transactions
    inscription_number_min
    inscription_number_max
    day_of_info

    constructor(data) {

        if (!data) throw 'Missing argument "data" from CollectionDailyInfo class constructor';
        this.assignJSONData(data);

    }

    assignJSONData(jsonData) {

        if (!jsonData) throw 'JSON Required to assign data to CollectionDailyInfo instance';

        jsonData = typeof jsonData === 'object' ? jsonData : JSON.parse(jsonData);
        this.collection_symbol = jsonData.collection_symbol;
        this.total_volume = jsonData.total_volume;
        this.owners = jsonData.owners;
        this.supply = jsonData.supply;
        this.floor_price = jsonData.floor_price;
        this.total_listed = jsonData.total_listed;
        this.pending_transactions = jsonData.pending_transactions;
        this.inscription_number_min = jsonData.inscription_number_min;
        this.inscription_number_max = jsonData.inscription_number_max;
        this.day_of_info = jsonData.day_of_info;

    }

    toRepositoryRecord() {

        const record = {};

        for (const key in this) {

            if (Object.hasOwnProperty.call(this, key)
                && typeof this[key] !== 'function'
                && typeof this[key] !== 'undefined') {

                const element = this[key];
                record[key] = element;

            }

        }

        return record;

    }

    static getCollectionsData(collectionsMarketInfo, btcPriceInUSD) {

        let totalPortfolioEstimatedValueInSats = 0;
        let yesterdayTotalPortfolioEstimatedValueInSats = 0;

        const newItems = [];
        const soldItems = [];
        const itemsWithChange = [];

        console.log('collectionsMarketInfo: ', collectionsMarketInfo);
        console.log('btcPriceInUSD: ', btcPriceInUSD);

        for (const collectionMarketInfo of collectionsMarketInfo) {

            console.log('collectionMarketInfo: ', collectionMarketInfo);

            totalPortfolioEstimatedValueInSats += (collectionMarketInfo?.today?.floorPrice * collectionMarketInfo?.today?.itemsQty) || 0;
            const todayItemsQty = collectionMarketInfo?.today?.itemsQty || 0;
            const FPInBTC = (collectionMarketInfo?.today?.floorPrice / (10 ** 8)) || 0;

            if (Object.keys(collectionMarketInfo).indexOf('yesterday') != -1) {

                yesterdayTotalPortfolioEstimatedValueInSats += collectionMarketInfo.yesterday.floorPrice * collectionMarketInfo.yesterday.itemsQty;

                if (Object.keys(collectionMarketInfo).indexOf('today') == -1) {

                    const yesterdayItemsQty = collectionMarketInfo?.yesterday?.itemsQty || 0;
                    const yesterdayFPInBTC = (collectionMarketInfo?.yesterday?.floorPrice / (10 ** 8)) || 0;

                    const soldItem = {

                        collection_name: collectionMarketInfo.name,
                        collection_symbol: collectionMarketInfo.collection_symbol,
                        collection_url: MAGICEDENURL + '/ordinals/marketplace/' + collectionMarketInfo.collection_symbol,
                        floor_price_in_btc: yesterdayFPInBTC,
                        floor_price_in_usd: !btcPriceInUSD ? '$ ?' : formatter.format(parseFloat((yesterdayFPInBTC * btcPriceInUSD).toFixed(2))),
                        yesterdayItemsQty,
                        total_estimated_value_in_btc: (yesterdayFPInBTC * yesterdayItemsQty).toFixed(8),
                        total_estimated_value_in_usd: !btcPriceInUSD ? '$ ?' : formatter.format((yesterdayFPInBTC * yesterdayItemsQty * btcPriceInUSD).toFixed(2))

                    }

                    soldItems.push(soldItem);

                } else {

                    const yesterdayFPInBTC = (collectionMarketInfo?.yesterday?.floorPrice / (10 ** 8)) || 0;

                    const yesterdayItemsQty = collectionMarketInfo?.yesterday?.itemsQty || 0;

                    const soldItems = yesterdayItemsQty - todayItemsQty;

                    const buyedItems = todayItemsQty - yesterdayItemsQty;

                    const FPPercentageChangeInSats = collectionMarketInfo.FPPercentageChange;

                    const FPPercentageChangeFormatted = FPPercentageChangeInSats > 0 ? ('+' + FPPercentageChangeInSats + '%') : FPPercentageChangeInSats + '%';

                    const itemWithChange = {

                        collection_name: collectionMarketInfo.name,
                        collection_symbol: collectionMarketInfo.collection_symbol,
                        collection_url: MAGICEDENURL + '/ordinals/marketplace/' + collectionMarketInfo.collection_symbol,
                        floor_price_in_btc: FPInBTC,
                        yesterday_floor_price_in_btc: yesterdayFPInBTC,
                        floor_price_in_usd: !btcPriceInUSD ? '?' : formatter.format(parseFloat((FPInBTC * btcPriceInUSD).toFixed(2))),
                        FPPercentageChangeInSats,
                        FPPercentageChangeFormatted,
                        todayItemsQty,
                        yesterdayItemsQty,
                        soldItems: (soldItems > 0 ? '\nITEMS DECREASE: -' + soldItems : ''),
                        buyedItems: (buyedItems > 0 ? '\nITEMS INCREASE: +' + buyedItems : ''),
                        total_estimated_value_in_btc: (FPInBTC * todayItemsQty).toFixed(8),
                        yesterday_total_estimated_value_in_usd: !btcPriceInUSD ? '$ ?' : formatter.format((yesterdayFPInBTC * yesterdayItemsQty * btcPriceInUSD).toFixed(2)),
                        total_estimated_value_in_usd: !btcPriceInUSD ? '$ ?' : formatter.format((FPInBTC * todayItemsQty * btcPriceInUSD).toFixed(2)),

                    }

                    itemsWithChange.push(itemWithChange);

                }

            } else {

                const newItem = {

                    collection_name: collectionMarketInfo.name,
                    collection_symbol: collectionMarketInfo.collection_symbol,
                    collection_url: MAGICEDENURL + '/ordinals/marketplace/' + collectionMarketInfo.collection_symbol,
                    floor_price_in_btc: FPInBTC,
                    floor_price_in_usd: !btcPriceInUSD ? '$ ?' : formatter.format(parseFloat((FPInBTC * btcPriceInUSD).toFixed(2))),
                    todayItemsQty,
                    total_estimated_value_in_btc: (FPInBTC * todayItemsQty).toFixed(8),
                    total_estimated_value_in_usd: !btcPriceInUSD ? '$ ?' : formatter.format((FPInBTC * todayItemsQty * btcPriceInUSD).toFixed(2))

                }

                newItems.push(newItem);

            }

        }

        const totalPortfolioEstimatedValueInBTC = totalPortfolioEstimatedValueInSats / (10 ** 8);
        const yesterdayTotalPortfolioEstimatedValueInBTC = yesterdayTotalPortfolioEstimatedValueInSats / (10 ** 8);

        const portfolioEstValueInUSD = !btcPriceInUSD ? 0 : parseFloat((totalPortfolioEstimatedValueInBTC * btcPriceInUSD).toFixed(2));
        const portfolioYesterdayEstValueInUSD = !btcPriceInUSD ? 0 : parseFloat((yesterdayTotalPortfolioEstimatedValueInBTC * btcPriceInUSD).toFixed(2));

        const portfolioEstValueInUSDFormatted = !btcPriceInUSD ? '$ ?' : formatter.format(parseFloat((totalPortfolioEstimatedValueInBTC * btcPriceInUSD).toFixed(2)));
        const portfolioYesterdayEstValueInUSDFormatted = !btcPriceInUSD ? '$ ?' : formatter.format(parseFloat((yesterdayTotalPortfolioEstimatedValueInBTC * btcPriceInUSD).toFixed(2)));

        const portfolioChangePercentageFromYesterday = parseFloat((((100 * totalPortfolioEstimatedValueInBTC) / yesterdayTotalPortfolioEstimatedValueInBTC) - 100).toFixed(2));

        const portfolioChangePercentageFromYesterdayFormatted = portfolioChangePercentageFromYesterday > 0 ? ('+' + portfolioChangePercentageFromYesterday + '%') : portfolioChangePercentageFromYesterday + '%';

        const changeInBTC = parseFloat(totalPortfolioEstimatedValueInBTC - yesterdayTotalPortfolioEstimatedValueInBTC).toFixed(8);
        const changeInBTCFormatted = portfolioChangePercentageFromYesterday < 0 ? changeInBTC : '+' + changeInBTC;

        const changeInUSD = parseFloat(portfolioChangePercentageFromYesterday < 0 ? (portfolioYesterdayEstValueInUSD - portfolioEstValueInUSD) : portfolioEstValueInUSD - portfolioYesterdayEstValueInUSD).toFixed(2);
        const changeInUSDFormatted = (!btcPriceInUSD ? '($ ?' : (portfolioChangePercentageFromYesterday < 0 ? '(-$ ' + changeInUSD : '(+$ ' + changeInUSD)) + ')  ';

        // const portfolioChangeMessage = (changeInBTC == 0? '' : changeInBTCFormatted + 'BTC ') +
        //     (changeInUSD == 0? '' : changeInUSDFormatted) + portfolioChangePercentageFromYesterdayFormatted;

        return { newItems, soldItems, itemsWithChange, totalPortfolioEstimatedValueInBTC, yesterdayTotalPortfolioEstimatedValueInBTC, portfolioEstValueInUSD, portfolioEstValueInUSDFormatted, portfolioYesterdayEstValueInUSD, portfolioYesterdayEstValueInUSDFormatted, changeInBTC, changeInBTCFormatted, changeInUSD, changeInUSDFormatted, portfolioChangePercentageFromYesterday, portfolioChangePercentageFromYesterdayFormatted };

    }

}

module.exports.CollectionDailyInfo = CollectionDailyInfo;