import { CollectionService } from '../../services/CollectionService';
import MagicEdenService from '../../services/MagicEdenService';
import { SubscriberFPNotificationService } from '../../services/SubscriberFPNotificationService';
import './FPNotificationsPage.css';
import { useEffect, useState } from 'react';

function FPNotificationsPage({
    userData,
    initData
}) {

    const [searchTerm, setSearchTerm] = useState(``);
    const [intervalId, setIntervalId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [lastInputTimestamp, setLastInputTimestamp] = useState(null);
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [periodicity, setPeriodicity] = useState(`daily`);
    const [MEBTCCollections, setMEBTCCollections] = useState(null);
    const [myFPNotifications, setMyFPNotifications] = useState(null);

    const queryMECollections = async (query) => {

        if (selectedCollection == null) {
            // QUERY FOR COLLECTIONS AVAILABLE ON ME
            const MECollections = await MagicEdenService.queryMECollections(query);
            const meBTCCollectionsRefresh = MECollections?.bitcoin || [];
            setMEBTCCollections(meBTCCollectionsRefresh);
        }

    }

    // const listenPressKeyStop = () => {
    //     const input = document.getElementById('input-search-collection');
    //     let lastKeyDownTimestamp = new Date().getTime();
    //     input.addEventListener('keydown', async () => {
    //         if (lastKeyDownTimestamp - new Date().getTime() > 1500 && selectedCollection == null) {
    //             if (searchTerm.length > 2) {
    //                 await queryMECollections(searchTerm);
    //             }
    //         }
    //         lastKeyDownTimestamp = new Date().getTime();
    //     })
    // }

    const getMyFPNotifications = async () => {
        try {
            setLoading(true);
            const inMyFPNotifs = await SubscriberFPNotificationService.getSubscriberFPNotifications(initData);
            setMyFPNotifications(inMyFPNotifs);
            setLoading(false);

        } catch (error) {
            setLoading(false);

        }
    }

    useEffect(() => {

        getMyFPNotifications();

    }, []);

    useEffect(() => {

    }, [userData]);

    useEffect(() => {

        console.log('MEBTCCollections: ', MEBTCCollections);

    }, [MEBTCCollections]);

    useEffect(() => {

        console.log('myFPNotifications: ', myFPNotifications);

    }, [myFPNotifications]);

    useEffect(() => {

        console.log('periodicity: ', periodicity);

    }, [periodicity]);

    useEffect(() => {

        console.log('searchTerm: ', searchTerm);

        if (searchTerm == null || searchTerm != selectedCollection?.name) {
            setSelectedCollection(null);
        }

    }, [searchTerm]);

    useEffect(() => {

        console.log('lastInputTimestamp: ', lastInputTimestamp);

        if (intervalId != null) {
            clearInterval(intervalId);
        }

        if (lastInputTimestamp != null) {
            let isLoading = false;
            const newIntervalId = setInterval(async () => {
                if (lastInputTimestamp != null && (new Date().getTime() - lastInputTimestamp) > 1000) {
                    if (searchTerm.length > 2 && isLoading == false && loading == false) {
                        setLoading(true);
                        isLoading = true;
                        await queryMECollections(searchTerm);
                        clearInterval(newIntervalId);
                        setLastInputTimestamp(null);
                        setLoading(false);
                        isLoading = false;
                    }
                }
            }, 100);
            setIntervalId(newIntervalId);
        }


    }, [lastInputTimestamp]);

    useEffect(() => {

        console.log('selectedCollection: ', selectedCollection);

        if (selectedCollection != null) {

            const selectedCollectionName = selectedCollection.name
            setSearchTerm(selectedCollectionName);
            setMEBTCCollections(null);

        }

    }, [selectedCollection]);

    const goToPortfolioTracker = () => {
        const url = window.location.href.split('?')[0].split('/')[0];
        window.location.search = new URLSearchParams();
        window.location.href = url + '?';
    }

    const handleCollectionSearch = async (e) => {
        const query = e.target.value;
        if (query != searchTerm) {
            setLastInputTimestamp(new Date().getTime());
            setSearchTerm(query);
        }
        if (query == null || query == `` || query.length < 3) {
            if (Array.isArray(MEBTCCollections)) {
                setMEBTCCollections(null);
            }
        } else {

        }
    }

    const submitFPNotification = async () => {

        try {
            setLoading(true);
            const selectedCollectionDetails = await MagicEdenService.getCollectionDetails(selectedCollection.symbol);
            console.log('selectedCollectionDetails: ', selectedCollectionDetails);
            const collectionResponse = await CollectionService.insert(initData, selectedCollectionDetails.json[0].collection);
            console.log('collectionResponse: ', collectionResponse);
            const collection_symbol = selectedCollectionDetails.json[0].collection.symbol;
            const floor_price = selectedCollectionDetails.json[0].collection.floorPrice;
            console.log('selectedCollectionDetails: ', selectedCollectionDetails);
            const newSubscriberFPNotificationResponse = await SubscriberFPNotificationService.insert(initData, { collection_symbol, periodicity, floor_price: selectedCollection.floorPrice });
            console.log('newSubscriberFPNotificationResponse: ', newSubscriberFPNotificationResponse);
            if (Array.isArray(newSubscriberFPNotificationResponse) && newSubscriberFPNotificationResponse.length > 0) {
                const newFPNotifs = [];
                newFPNotifs.push(newSubscriberFPNotificationResponse[0]);
                for (const existingFPNotif of myFPNotifications) {
                    newFPNotifs.push(existingFPNotif);
                }
                setMyFPNotifications(newFPNotifs);
                alert('Created: '+newSubscriberFPNotificationResponse[0].name+' | FP change '+periodicity+' notification.');
            } else {
                await getMyFPNotifications();
            }

            // periodicity
            // collection_symbol
            setLoading(false);
        } catch (error) {
            setLoading(false);
            alert('Unhandled error: ' + error);
        }

    }

    const deleteFPNotification = async (fpNotification, fpNotificationIdx) => {
        try {
            setLoading(true);
            const response = await SubscriberFPNotificationService.delete(initData, { collection_symbol: fpNotification.symbol, created_at: fpNotification.created_at })
            if (response) {
                const myFPNotificationsCopy = [];
                for (const existingFPNotif of myFPNotifications) {
                    myFPNotificationsCopy.push(existingFPNotif);
                }
                myFPNotificationsCopy.splice(fpNotificationIdx, 1);
                setMyFPNotifications(myFPNotificationsCopy);
                alert(`Deleted: ` + fpNotification.name + ` | FP change ` + fpNotification.periodicity + ` notification.`);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            alert('Unhandled error: ' + error);
        }
    }

    return (
        <div className="FPNotification-App">
            <header className="FPNotification-header disable-select">
                <div className='row'>
                    <div style={{ width: `33%`, marginRight: '1em', float: `left`, alignContent: `start` }}>
                        <p onClick={() => { goToPortfolioTracker() }} className='glowingMoon limit-text FPNotification-viewer-button' >Go back 🔙</p>
                    </div>
                    <div style={{ width: `33%` }} className='snap-welcomeAnimation'>
                        {userData && userData != null ?
                            <p className='glowingMoon' >
                                🧙‍♂😺🪄✨🌕<br />
                                Hello {userData.user.first_name + ' ' + userData.user.last_name}!
                            </p>
                            :
                            <></>
                        }
                    </div>
                    <div style={{ width: `33%` }}>
                    </div>
                </div>
            </header>
            <div className='search-input-container column'>
                <div className='row'>
                    {selectedCollection == null ?
                        <></> :
                        <div className='box-center selected-image-container'>
                            <img className='selected-image' src={selectedCollection.image} alt={"Selected collection image (" + selectedCollection.name + ")"} />
                        </div>
                    }
                    <input disabled={loading} id='input-search-collection' className='collection-search-input' type='text' value={searchTerm} onChange={async (e) => await handleCollectionSearch(e)}
                        placeholder='Search for a collection listed on ME' />
                </div>
                {selectedCollection == null ?
                    <></> :
                    <div className='box-center'>
                        <div className='column' style={{paddingLeft: `2em`}}>
                            <label style={{ color: 'orange', fontSize: '1.125em' }} htmlFor='periodicity'>Choose the 🔔 periodicity</label>
                            <div className='row'>
                                <select disabled={loading} id='periodicity' value={periodicity}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setPeriodicity(value);
                                    }}>
                                    <option value={`daily`}>daily</option>
                                    <option value={`hourly`}>hourly</option>
                                </select>
                            </div>
                        </div>
                        <button disabled={loading} onClick={async () => await submitFPNotification()} className='add-collection-notification' >
                            ➕ Add collection FP change to my notifications
                        </button>
                    </div>
                }
                {Array.isArray(MEBTCCollections) ?
                    <div className='me-collections-container'>
                        {MEBTCCollections.map((collection, collectionIdx) => {
                            return (
                                <div onClick={() => setSelectedCollection(collection)} className='me-collection-line row' key={collectionIdx}>
                                    <img className='me-collection-line-image' src={collection.image} alt={collection.name + ' image'} />
                                    <p>{collection.name}</p>
                                </div>
                            );
                        })}
                    </div>
                    : <></>}
            </div>
            <div className='my-notifications-header row'>
                <button onClick={async (e) => { await getMyFPNotifications() }}
                    disabled={loading}
                    style={{ background: `transparent`, border: `none`, fontStyle: 'oblique', color: `#fff`, fontSize: `1.125em`, padding: '1em', cursor: `pointer` }}> Click 
                    <span style={{fontStyle: 'italic', color: 'ORANGE'}}> HERE </span>to refresh
                    <span className='refresh-my-notifications'>🔃</span>
                     FP change active notifications 👇🏻🔔
                </button>
            </div>
            <div className='my-notifications-body'>
                {!Array.isArray(myFPNotifications) ?
                    <></>
                    :
                    <>
                        {
                            myFPNotifications.map((fpNotification, fpNotificationIdx) => {
                                return (
                                    <div key={fpNotificationIdx} className='row my-fp-notification-container'>
                                        <div className='box-center fp-notification-image-container'>
                                            <img className='fp-notification-image' src={fpNotification.image_uri} alt={"My active fp notification " + fpNotificationIdx + " image (" + fpNotification.name + ")"} />
                                        </div>
                                        <div className='column'>
                                            <p>
                                                <span style={{fontSize: '1.125em'}}>✅ {fpNotification.name}</span>
                                                <br/>
                                                <span>🔔 Periodicity: {fpNotification.periodicity}</span>
                                            </p>
                                        </div>
                                        <button disabled={loading} onClick={async () => await deleteFPNotification(fpNotification, fpNotificationIdx)} className='button-delete'>Delete 🗑️</button>
                                    </div>
                                );
                            })
                        }
                    </>
                }
            </div>
        </div>
    );
}

export default FPNotificationsPage;
