import { useEffect, useState } from 'react';
import './PortfolioViewerPage.css';
import { CollectionDailyInfo } from '../../utils/CollectionDailyInfo';

function PortfolioViewerPage({
    userData,
    subscriberPortfolio,
    setSubscriberPortfolio
}) {

    const [btcPrice, setBtcPrice] = useState(subscriberPortfolio.BTCPrice);
    const [btcPriceDisplayColor, setBtcPriceDisplayColor] = useState(`inherit`);
    const [collectionsInfo, setCollectionsInfo] = useState();
    const [sortOptions, setSortOptions] = useState([
        'fpca',
        'ca',
        'fpc',
        'fpv',
        'qty',
        'tev',
    ]);
    const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0]);
    const [sortOrder, setSortOrder] = useState('desc');
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [filtersOptions, setFiltersOptions] = useState([
        'new', 'sold', 'changed', 'no-changes'
    ]);
    const [filters, setFilters] = useState(filtersOptions);

    useEffect(() => {

        if (typeof subscriberPortfolio === `object`) {

            let ws = new WebSocket('wss://push.coinmarketcap.com/ws?device=web');

            ws.onopen = function () {
                //Subscribe to the channel
                const params = {
                    method
                        :
                        "RSUBSCRIPTION",
                    params
                        :
                        ["main-site@crypto_price_5s@{}@detail", "1,1027,2010,1839"]
                };
                const params2 = {
                    method
                        :
                        "RSUBSCRIPTION",
                    params
                        :
                        ["main-site@crypto_price_15s@{}@detail", "1,1027,2010,1839"]
                };
                ws.send(JSON.stringify(params2))
                ws.send(JSON.stringify(params))
            }

            ws.onmessage = function (msg) {
                const msgData = JSON.parse(msg?.data);
                if (msgData.d && Number(msgData.d.id) == 1) {
                    console.log(`msgData? `, msgData);
                    const incomingBTCPrice = msgData.d.p;
                    setBtcPriceDisplayColor(incomingBTCPrice > btcPrice ? `green` : incomingBTCPrice < btcPrice ? `red` : 'inherit');
                    setBtcPrice(msgData.d.p);
                }
            }
        }

    }, []);

    useEffect(() => {

        subscriberPortfolio.BTCPrice = btcPrice;

        setSubscriberPortfolio(subscriberPortfolio);

        if (Array.isArray(subscriberPortfolio.orderedCollectionsMarketInfo)) {

            setCollectionsInfo(CollectionDailyInfo.getCollectionsData(subscriberPortfolio.orderedCollectionsMarketInfo, subscriberPortfolio.BTCPrice));

        }

    }, [btcPrice, subscriberPortfolio, subscriberPortfolio.orderedCollectionsMarketInfo]);

    useEffect(() => {

        console.log('collectionsInfo: ', collectionsInfo);

    }, [collectionsInfo]);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const handleSortOrder = (e) => {

        const val = e.target.value;
        console.log('Sorting: ', val);
        setSortOrder(val);

    }

    const handleSort = (e) => {
        console.log(`handleSort`, e.target.value);
        setSelectedSortOption(e.target.value);
    }

    const handleFilters = (e) => {
        console.log(`handleFilters`, e.target.value);
        const eId = e.target.id;
        console.log(`eId`, eId);
        const isDiv = eId.indexOf('div-') != -1;
        console.log(`isDiv`, isDiv);
        const isChecked = isDiv? !document.getElementById(eId.replace('div-',``)).checked : e.target.checked;
        console.log(`isChecked`, isChecked);
        const selectedFilter =  isDiv? document.getElementById(eId.replace('div-',``)).value : e.target.value;
        console.log(`selectedFilter`, selectedFilter);
        let filtersNewRef = [];
        if (selectedFilter == 'all') {
            if (isChecked) {
                for (const filterOption of filtersOptions) {
                    filtersNewRef.push(filterOption);
                    document.getElementById(filterOption).checked = true;
                    isDiv? (document.getElementById(eId.replace('div-',``)).checked = true) : (e.target.checked = true);
                }
            } else {
                for (const filterOption of filtersOptions) {
                    document.getElementById(filterOption).checked = false;
                    isDiv? (document.getElementById(eId.replace('div-',``)).checked = false) : (e.target.checked = false);
                }
            }
            setFilters(filtersNewRef);
            console.log(`selectedFilter`, selectedFilter);
        } else {
            filtersNewRef = JSON.parse(JSON.stringify(filters));
            if (isChecked) {
                filtersNewRef.push(selectedFilter);
                document.getElementById(selectedFilter).checked = true;
            } else {
                filtersNewRef.splice(filtersNewRef.indexOf(selectedFilter), 1);
                document.getElementById(selectedFilter).checked = false;
            }
            setFilters(filtersNewRef);
            if (filtersNewRef.length == filtersOptions.length) {
                document.getElementById('all').checked = true;
            } else {
                document.getElementById('all').checked = false;

            }
        }
    }

    useEffect(() => {
        sortCollections();
    }, [selectedSortOption, sortOrder]);

    const sortCollections = () => {

        const sortOptionCompare = {
            'fpca': (a, b) => {
                const aTotal = a.AbsoluteFPPercentageChange || 0;
                const bTotal = b.AbsoluteFPPercentageChange || 0;
                return sortOrder == `asc` ? aTotal - bTotal :
                    bTotal - aTotal;
            },
            'ca': (a, b) => {
                const aName = a.name.toUpperCase();
                const bName = b.name.toUpperCase();
                return sortOrder == `asc` ? aName.localeCompare(bName) :
                    bName.localeCompare(aName);
            },
            'fpc': (a, b) => {
                const aTotal = a.FPPercentageChange || 0;
                const bTotal = b.FPPercentageChange || 0;
                return sortOrder == `asc` ? aTotal - bTotal :
                    bTotal - aTotal;
            },
            'fpv': (a, b) => {
                const aTotal = a.today?.floorPrice || 0;
                const bTotal = b.today?.floorPrice || 0;
                return sortOrder == `asc` ? aTotal - bTotal :
                    bTotal - aTotal;
            },
            'qty': (a, b) => {
                const aTotal = a.today?.itemsQty || 0;
                const bTotal = b.today?.itemsQty || 0;
                return sortOrder == `asc` ? aTotal - bTotal :
                    bTotal - aTotal;
            },
            'tev': (a, b) => {
                const aTotal = (a.today?.floorPrice || 0) * (a.today?.itemsQty || 0);
                const bTotal = (b.today?.floorPrice || 0) * (b.today?.itemsQty || 0);
                return sortOrder == `asc` ? aTotal - bTotal :
                    bTotal - aTotal;
            },
        }

        console.log(`selectedSortOption: `, selectedSortOption);

        const orderedRef = JSON.parse(JSON.stringify(subscriberPortfolio));
        orderedRef.orderedCollectionsMarketInfo.sort(sortOptionCompare[selectedSortOption])
        setSubscriberPortfolio(orderedRef);

    }

    const handleFiltersList = (e) => {

        const docs = document.getElementsByClassName(`filters-list`)[0];
        if (filtersOpen) {
            docs.style.height = '0';
            docs.style.opacity = '0';
            e.target.innerHTML = `Open filters`;
            e.target.style.background = `#000`;
            e.target.style.color = `orange`;
            setFiltersOpen(false);
        } else {
            docs.style.height = '100%';
            docs.style.opacity = '1';
            e.target.innerHTML = `Close filters`;
            e.target.style.background = `orange`;
            e.target.style.color = `#000`;
            setFiltersOpen(true);
        }
    }

    const goToSnapshot = () => {
        const url = window.location.href.split('/')[0] + '?action=snapshot-viewer';
        window.location.href = url;
    }

    const goToFPNotifications = () => {
        const url = window.location.href.split('/')[0] + '?action=fp-notifications';
        window.location.href = url;
    }

    return (
        <div className="Portfolio">
            <header className="PortfolioHeader disable-select">
                <div className='row flex-center'>
                    <div style={{ width: `33%`, marginLeft: '1em', marginRight: '1em', float: `left`, alignContent: `start` }}>
                        <p onClick={() => { goToSnapshot() }} className='glowingMoon limit-text snapshot-viewer-button' >Snapshot viewer 👀</p>
                    </div>
                    <div>
                        <div className='portfolioWelcomeAnimation'>
                            <p className='glowingMoon' >
                                🧙‍♂😺🪄✨🌕<br />
                                Hello {userData.user.first_name + ' ' + userData.user.last_name}!
                            </p>
                        </div>
                        {subscriberPortfolio && subscriberPortfolio != null && typeof collectionsInfo === `object` ?

                            <div>
                                <p className='glowingMoon' >
                                    1 BTC = <span className='glowingMoon' style={{ color: btcPriceDisplayColor }}>{formatter.format(subscriberPortfolio.BTCPrice)}</span>
                                </p>

                            </div>

                            :
                            <></>
                        }
                    </div>
                    <div style={{ width: `33%`, marginLeft: '1em', marginRight: '1em', float: `left`, alignContent: `start` }}>
                        <p onClick={() => { goToFPNotifications() }} className='glowingMoon limit-text snapshot-viewer-button' >FP change notifications 🔔</p>
                    </div>
                </div>
            </header>
            {subscriberPortfolio && subscriberPortfolio != null && typeof collectionsInfo === `object` ?
                <div className='portfolioContainer'>
                    {!Array.isArray(subscriberPortfolio.orderedCollectionsMarketInfo) ||
                        (Array.isArray(subscriberPortfolio.orderedCollectionsMarketInfo)
                            &&
                            subscriberPortfolio.orderedCollectionsMarketInfo.length == 0) ?
                        <div className='portfolioList'>
                            <div className='portfolioResume'>
                                <p className='glowingMoon limit-text' >YOU DON'T HAVE ANY ITEMS IN YOUR PORTFOLIO TRACKER AT THE MOMENT . . .<br />BUY SOME ITEMS AT MAGIC EDEN AND COME BACK AFTER 0PM UTC 🌕!</p>
                            </div>
                        </div>
                        :
                        <div className='portfolioList'>
                            <div className='portfolioResume'>
                                {/* yesterdayTotalPortfolioEstimatedValueInBTC, portfolioEstValueInUSD, portfolioYesterdayEstValueInUSD */}
                                <p className='glowingMoon limit-text' >PORTFOLIO ESTIMATED VALUE: {collectionsInfo.totalPortfolioEstimatedValueInBTC} BTC <span className='glowingMoon' style={{ color: btcPriceDisplayColor }}>({collectionsInfo.portfolioEstValueInUSDFormatted})</span> <span className='glowingMoon' style={{ color: collectionsInfo.portfolioChangePercentageFromYesterdayFormatted.indexOf(`+`) != -1 ? `green` : (collectionsInfo.portfolioChangePercentageFromYesterdayFormatted.indexOf(`-`) != -1) ? `red` : `inherit` }}>{collectionsInfo.portfolioChangePercentageFromYesterdayFormatted}</span></p>
                                <p className='glowingMoon limit-text' >YESTERDAY ESTIMATED VALUE: {collectionsInfo.yesterdayTotalPortfolioEstimatedValueInBTC} BTC <span className='glowingMoon' style={{ color: btcPriceDisplayColor }}>({collectionsInfo.portfolioYesterdayEstValueInUSDFormatted})</span></p>
                                <p className='glowingMoon limit-text' >{subscriberPortfolio.orderedCollectionsMarketInfo.length} MAGIC EDEN COLLECTIONS IN YOUR TRACKER (Vol > 0) 🪄✨🌕</p>
                            </div>
                            <div className='row'>
                                <div className='sort-container'>
                                    <div>
                                        <span>
                                            Sort by:
                                        </span>
                                    </div>
                                    <div>
                                        <select className='select' onChange={(e) => { handleSort(e) }}>
                                            <option className='option' value={`fpca`}>Absolute floor price change</option>
                                            <option className='option' value={`ca`}>Collection name</option>
                                            <option className='option' value={`fpc`}>Floor price change</option>
                                            <option className='option' value={`fpv`}>Floor price value</option>
                                            <option className='option' value={`qty`}>Holding quantity</option>
                                            <option className='option' value={`tev`}>Total estimated value</option>
                                        </select>
                                    </div>
                                    <div>
                                        <span>
                                            Order:
                                        </span>
                                    </div>
                                    <div>
                                        <select className='select' onChange={(e) => handleSortOrder(e)} >
                                            <option className='option' value={`desc`}>Desc</option>
                                            <option className='option' value={`asc`}>Asc</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='filters-container'>
                                    <div id="filters-container-button" onClick={(e) => { handleFiltersList(e) }} className='row filters-container'>
                                        Open filters
                                    </div>
                                    <div className='filters-list'>
                                        <div id="div-all" onClick={(e) => { handleFilters(e) }} className='checkbox-container'>
                                            <label htmlFor='all' >All</label>
                                            <input value={'all'} className='input-checkbox' id="all" type='checkbox' onChange={(e) => { handleFilters(e) }} defaultChecked={filters.length == filtersOptions.length} />
                                        </div>
                                        <div id="div-new" onClick={(e) => { handleFilters(e) }} className='checkbox-container'>
                                            <label htmlFor='new' >New collections</label>
                                            <input value={'new'} className='input-checkbox' id="new" type='checkbox' onChange={(e) => { handleFilters(e) }} defaultChecked={filters.indexOf(`new`) != -1} />
                                        </div>
                                        <div id="div-sold" onClick={(e) => { handleFilters(e) }} className='checkbox-container'>
                                            <label htmlFor='sold' >Sold collections</label>
                                            <input value={'sold'} className='input-checkbox' id="sold" type='checkbox' onChange={(e) => { handleFilters(e) }} defaultChecked={filters.indexOf(`sold`) != -1} />
                                        </div>
                                        <div id="div-changed" onClick={(e) => { handleFilters(e) }} className='checkbox-container'>
                                            <label htmlFor='changed' >Collections with changes</label>
                                            <input value={'changed'} className='input-checkbox' id="changed" type='checkbox' onChange={(e) => { handleFilters(e) }} defaultChecked={filters.indexOf(`changed`) != -1} />
                                        </div>
                                        <div id="div-no-changes" onClick={(e) => { handleFilters(e) }} className='checkbox-container'>
                                            <label htmlFor='no-changes' >Collections with no changes</label>
                                            <input value={'no-changes'} className='input-checkbox' id="no-changes" type='checkbox' onChange={(e) => { handleFilters(e) }} defaultChecked={filters.indexOf(`no-changes`) != -1} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                subscriberPortfolio.orderedCollectionsMarketInfo.map(collectionMarketInfo => {

                                    const isNew = collectionsInfo.newItems.some(item => item.collection_name == collectionMarketInfo.name);
                                    const haveSold = collectionsInfo.soldItems.some(item => item.collection_name == collectionMarketInfo.name);
                                    const haveChanged = collectionsInfo.itemsWithChange.some(item => item.collection_name == collectionMarketInfo.name);

                                    const collectionInfo = isNew ? collectionsInfo.newItems.find(item => item.collection_name == collectionMarketInfo.name) :
                                        haveSold ?
                                            collectionsInfo.soldItems.find(item => item.collection_name == collectionMarketInfo.name) :
                                            collectionsInfo.itemsWithChange.find(item => item.collection_name == collectionMarketInfo.name);

                                    if (isNew && filters.indexOf('new') != -1) {

                                        return (
                                            <div key={collectionMarketInfo.name} className='portfolioListItem'>
                                                <div className='glowingMoon portfolioListItemHeader'>
                                                    <p>NEW COLLECTION <span style={{ color: 'green' }}>ADDED</span> TO PORTFOLIO!<br />
                                                        ADDED: {collectionInfo.todayItemsQty}</p>
                                                </div>
                                                <div className='collectionImageContainer'>
                                                    <div className='row'>
                                                        <div className='column'>
                                                            <div className='collectionImageBox'>
                                                                <img src={collectionMarketInfo.image_uri} alt='Collection logo image' />
                                                                <div className='row'>
                                                                    <p className='glowingMoon'>FLOOR PRICE: {collectionInfo.floor_price_in_btc} BTC <span className='glowingMoon' style={{ color: btcPriceDisplayColor }}>({collectionInfo.floor_price_in_usd})</span></p><br />
                                                                </div>
                                                            </div>
                                                            <a className='glowingMoon' href={collectionInfo.collection_url} target='_blank'>{collectionMarketInfo.name}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className='glowingMoon'>TOTAL: {collectionInfo?.total_estimated_value_in_btc} BTC <span className='glowingMoon' style={{ color: btcPriceDisplayColor }}>({collectionInfo.total_estimated_value_in_usd})</span></p>
                                            </div>
                                        );

                                    } else if (haveSold && filters.indexOf('sold') != -1) {

                                        return (
                                            <div key={collectionMarketInfo.name} className='portfolioListItem'>
                                                <div className='glowingMoon portfolioListItemHeader'>
                                                    <p style={{ width: '100%' }}>ALL COLLECTION ITEMS <span style={{ color: 'red' }}>SOLD</span>!<br />
                                                        SOLD: {collectionInfo?.yesterdayItemsQty}<br /></p>
                                                </div>
                                                <div className='collectionImageContainer'>
                                                    <div className='row'>
                                                        <div className='column'>
                                                            <div className='collectionImageBox'>
                                                                <img src={collectionMarketInfo.image_uri} alt='Collection logo image' />
                                                                <div className='row'>
                                                                    <p className='glowingMoon'>FLOOR PRICE: {collectionInfo?.floor_price_in_btc} BTC <span className='glowingMoon' style={{ color: btcPriceDisplayColor }}>({collectionInfo.floor_price_in_usd})</span></p><br />
                                                                </div>
                                                            </div>
                                                            <a className='glowingMoon' href={collectionInfo.collection_url} target='_blank'>{collectionMarketInfo.name}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className='glowingMoon'>TOTAL SOLD: {collectionInfo?.total_estimated_value_in_btc} BTC <span className='glowingMoon' style={{ color: btcPriceDisplayColor }}>({collectionInfo.total_estimated_value_in_usd})</span></p>
                                            </div>
                                        );

                                    } else {

                                        if (haveChanged && (filters.indexOf('changed') != -1 || filters.indexOf('no-changes') != -1)) {

                                            if (((collectionInfo.soldItems.length > 0 || collectionInfo.buyedItems.length > 0 || collectionMarketInfo.AbsoluteFPPercentageChange != 0) && filters.indexOf('changed') != -1) ||
                                                (collectionMarketInfo.AbsoluteFPPercentageChange == 0 && filters.indexOf('no-changes') != -1))

                                                return (
                                                    <div key={collectionMarketInfo.name} className='portfolioListItem'>
                                                        <div className='glowingMoon portfolioListItemHeader'>
                                                            <p>FLOOR PRICE CHANGE (1d): <span className='glowingMoon' style={{ color: collectionInfo.FPPercentageChangeFormatted.indexOf('+') != -1 ? 'green' : collectionInfo.FPPercentageChangeFormatted.indexOf('-') != -1 ? 'red' : 'inherit' }}>{collectionInfo.FPPercentageChangeFormatted}</span><br />
                                                                FLOOR PRICE: {collectionInfo.floor_price_in_btc} BTC <span className='glowingMoon' style={{ color: btcPriceDisplayColor }}>({collectionInfo.floor_price_in_usd})</span></p>
                                                        </div>
                                                        <div className='collectionImageContainer'>
                                                            <div className='row'>
                                                                <div className='column'>
                                                                    <div className='collectionImageBox'>
                                                                        <img src={collectionMarketInfo.image_uri} alt='Collection logo image' />
                                                                        <div style={{maxWidth: '50vw', transform: 'scale(0.9)'}} className='column'>
                                                                            {collectionInfo.soldItems.length > 0 ?
                                                                                <p style={{ color: 'red', width: '50%', maxWidth: `25vw`, marginRight: `.5em` }} className='glowingMoon' >{collectionInfo.soldItems}</p> : <></>}
                                                                            {collectionInfo.buyedItems.length > 0 ?
                                                                                <p style={{ color: 'green', width: '50%', maxWidth: `25vw`, marginRight: `.5em` }} className='glowingMoon' >{collectionInfo.buyedItems}</p> : <></>}
                                                                            {collectionInfo.soldItems.length > 0 || collectionInfo.buyedItems.length > 0 ?
                                                                                <p style={{ maxWidth: `25vw`, marginLeft: '0'}} className='glowingMoon'>HOLDING: {collectionInfo.todayItemsQty}</p>
                                                                                :
                                                                                <p className='glowingMoon'>HOLDING: {collectionInfo.todayItemsQty}</p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <a className='glowingMoon' href={collectionInfo.collection_url} target='_blank'>{collectionMarketInfo.name}</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p className='glowingMoon'>TOTAL: {collectionInfo.total_estimated_value_in_btc} BTC <span className='glowingMoon' style={{ color: btcPriceDisplayColor }}>({collectionInfo.total_estimated_value_in_usd})</span></p>
                                                    </div>
                                                );

                                        }

                                    }

                                })

                            }

                        </div>
                    }
                </div>
                : <></>
            }
        </div >
    );
}

export default PortfolioViewerPage;