class TimingUtil {

    async waitSeconds(seconds) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, [1000 * seconds]);
        });
    }

}

export default new TimingUtil();