const REACT_APP_API_URL = process.env.REACT_APP_API_URL; 

class CollectionService {

    async insert(initData, collection, retries) {

        try {
            
            retries = typeof retries === 'number'? retries : 0;
    
            if ( retries > 3 ) {
    
                alert('Sorry, could not insert collection data.\nPlease try again later');
                // window.close();
    
            }
            
            const body = {
                telegramData: initData,
                collection
            }

            const response = await fetch(REACT_APP_API_URL+'/collection', {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-type': 'application/json'
                }
            })
    
            if ( response.status == 200 ) {
    
                return await response.json();
    
            } else {
    
                console.error('Error to insert collection: ', await response.text(), '\nStatus code: ', response.status);
    
                if ( response.status == 401 ) {
    
                    alert('Sorry, you are not a Wizard!');
                    window.close();
    
                }

                retries += 1;
                
                return await this.insert(initData, collection, retries);
    
            }

        } catch (error) {

            alert('Error inserting collection data: '+error+'\nPlease try again later.');
            // window.close();
            
        }

    }
}

module.exports.CollectionService = new CollectionService();