const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

class SubscriberFPNotificationService {

    async getSubscriberFPNotifications(initData, retries) {

        try {

            retries = typeof retries === 'number' ? retries : 0;

            if (retries > 3) {

                alert('Sorry, could not get subscriber FP notifications.\nPlease try again later');
                return;
                // window.close();

            }

            const body = {
                telegramData: initData
            }

            const response = await fetch(REACT_APP_API_URL + '/getFPNotifications', {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-type': 'application/json'
                }
            })

            if (response.status == 200) {

                return await response.json();

            } else {

                console.error('Error to get subscriber FP notifications: ', await response.text(), '\nStatus code: ', response.status);

                if (response.status == 401) {

                    alert('Sorry, you are not a Wizard!');
                    window.close();

                }

                retries += 1;

                return await this.getSubscriberFPNotifications(initData, retries);

            }

        } catch (error) {

            alert('Error to get subscriber FP notifications: ' + error + '\nPlease try again later.');
            return;
            // window.close();

        }

    }

    async insert(initData, { collection_symbol, periodicity, floor_price }, retries) {

        try {

            retries = typeof retries === 'number' ? retries : 0;

            if (retries > 3) {

                alert('Sorry, could not insert subscriber FP notification data.\nPlease try again later');
                return;
                // window.close();

            }

            const body = {
                telegramData: initData,
                collection_symbol,
                periodicity,
                floor_price
            }

            const response = await fetch(REACT_APP_API_URL + '/createFPNotification', {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-type': 'application/json'
                }
            })

            if (response.status == 200) {

                return await response.json();

            } else {

                const resText = await response.text();

                console.error('Error to insert subscriber FP notification: ', resText, '\nStatus code: ', response.status);

                if (response.status == 401) {

                    alert('Sorry, you are not a Wizard!');
                    window.close();

                } else if (response.status == 400) {

                    if (resText.length > 0) {
                        alert(resText);
                        return;
                    }
                }

                retries += 1;

                return await this.insert(initData, { collection_symbol, periodicity }, retries);

            }

        } catch (error) {

            alert('Error inserting fp notification data: ' + error + '\nPlease try again later.');
            return;
            // window.close();

        }

    }

    async delete(initData, { collection_symbol, created_at }, retries) {

        try {

            retries = typeof retries === 'number' ? retries : 0;

            if (retries > 3) {

                alert('Sorry, could not delete subscriber FP notification data.\nPlease try again later');
                return;
                // window.close();

            }

            const body = {
                telegramData: initData,
                collection_symbol,
                created_at
            }

            const response = await fetch(REACT_APP_API_URL + '/deleteFPNotification', {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-type': 'application/json'
                }
            })

            if (response.status == 200) {

                return await response.json();

            } else {

                console.error('Error to delete subscriber FP notification: ', await response.text(), '\nStatus code: ', response.status);

                if (response.status == 401) {

                    alert('Sorry, you are not a Wizard!');
                    window.close();

                }

                retries += 1;

                return await this.delete(initData, { collection_symbol, created_at }, retries);

            }

        } catch (error) {

            alert('Error deleting fp notification data: ' + error + '\nPlease try again later.');
            return;
            // window.close();

        }

    }

}

module.exports.SubscriberFPNotificationService = new SubscriberFPNotificationService();