import TimingUtil from "../utils/TimingUtil";

const REACT_APP_PROXY_URL = process.env.REACT_APP_PROXY_URL;

class MagicEdenService {

    #ordinalsMktBaseURL = process.env.REACT_APP_MAGICEDENAPIBASEURL + '/' + process.env.REACT_APP_MAGICEDENORDINALSPATH;
    #ordinalsBaseURL = process.env.REACT_APP_MAGICEDENAPIBASEURL + '/unifiedSearch/xchain/collection/';

    async queryMECollections(searchTerm) {
        // https://api-mainnet.magiceden.us/v2/unifiedSearch/xchain/collection/wizard?edge_cache=true&limit=100

        const limit = 100;
        const cacheEnabled = `false`;

        const queryParams = `?edge_cache=` + cacheEnabled + `&limit=` + limit;

        try {

            const URL = REACT_APP_PROXY_URL + this.#ordinalsBaseURL + searchTerm + queryParams;

            let response = await fetch(URL, {
                headers: {
                    'User-Agent': 'Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/128.0.0.0 Mobile Safari/537.36',
                    'Accept': `application/json`,
                    'ngrok-skip-browser-warning': 'true'
                }
            });

            console.log('URL: ', URL);

            if (response.status >= 400) {

                await TimingUtil.waitSeconds(.125);
                const MEIoDomain = URL.replace('.us/', '.io/');
                response = await fetch(MEIoDomain, {
                    headers: {
                        'User-Agent': 'Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/128.0.0.0 Mobile Safari/537.36',
                        'Accept': `application/json`,
                        'ngrok-skip-browser-warning': 'true'
                    }
                });
                console.log('Changing ME request domain server from .us to .io\nURL: ', MEIoDomain);

            }

            const status = response.status || 200;

            if (status == 200) {

                const json = response.status ? await response.json() : response;

                return json;

            } else {

                const responseText = await response.text();

                console.log('QUERY ME COLLECTIONS REQUEST FAILED WITH STATUS ', status, '\nRESPONSE: ', responseText);

            }

        } catch (error) {

            console.log('QUERY ME COLLECTIONS REQUEST FAILED\nERROR: ', error);

        }

    }

    async fetchCollectionHoldersByPage(collectionSymbol, page) {

        if (!page) {

            page = 0;

        }

        const limit = 100;

        const offset = page * limit;

        const returnResponse = {
            status: 429,
            tokens: []
        };

        // LIMIT SNAPSHOT IN 25K COLLECTION ITEMS
        if (offset > (10 ** 4) * 2.5) {

            returnResponse.status = 200;

            return returnResponse;

        }

        const URL = REACT_APP_PROXY_URL + this.#ordinalsMktBaseURL + '/btc/wallets/tokens?' +
            'collectionSymbol=' + collectionSymbol + '&' +
            'showAll=true&' +
            'sortBy=inscriptionNumberAsc&' +
            'limit=100&' +
            'offset=' + offset;

        let response = await fetch(URL, {
            headers: {
                'User-Agent': 'Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/128.0.0.0 Mobile Safari/537.36',
                'Accept': `application/json`,
                'ngrok-skip-browser-warning': 'true'
            }
        });

        console.log('URL: ', URL);

        if (response.status >= 400) {

            await TimingUtil.waitSeconds(.125);
            const MEIoDomain = URL.replace('.us/', '.io/');
            response = await fetch(MEIoDomain, {
                headers: {
                    'User-Agent': 'Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/128.0.0.0 Mobile Safari/537.36',
                    'Accept': `application/json`,
                    'ngrok-skip-browser-warning': 'true'
                }
            });
            console.log('Changing ME request domain server from .us to .io\nURL: ', MEIoDomain);

        }

        const status = response.status || 200;

        returnResponse.status = status;

        if (status == 200) {

            const json = response.status ? await response.json() : response;

            const tokens = json?.tokens;

            returnResponse.tokens = Array.isArray(tokens) ? tokens : [];

        } else {

            const responseText = await response.text();

            console.log('GET COLLECTIONS HOLDERS FAILED WITH STATUS ', status, '\nRESPONSE: ', responseText);

        }

        console.log('returnResponse.status: ', returnResponse.status);
        console.log('returnResponse.tokens: ', returnResponse.tokens.length);

        return returnResponse;

    }

    async getCollectionDetails(collectionSymbol) {

        console.log('Starting get of collection "' + collectionSymbol + '" details at ' + new Date().toISOString());

        const response = {
            success: true,
            // holdersAddresses: {}
            json: []
        };

        try {

            let lastResponse = null;
            // {
            //     status: 200,
            //     tokens: []
            // }
            let page = 0;

            while (lastResponse == null) {

                try {

                    lastResponse = await this.fetchCollectionHoldersByPage(collectionSymbol, 0);

                    if (lastResponse.status == 200) {

                        for (const token of lastResponse.tokens) {

                            response.json.push(token);
                            // if (Object.keys(response.holdersAddresses).indexOf(token.owner) == -1) {

                            //     response.holdersAddresses[token.owner] = { itemsCount: 1 };

                            // } else {

                            //     response.holdersAddresses[token.owner].itemsCount += 1;

                            // }

                        }

                        page += 1;

                        console.log('Waiting 250ms to make next request...');
                        await TimingUtil.waitSeconds(.25);

                    } else {

                        await TimingUtil.waitSeconds(lastResponse.status == 429 ? 60 : 1);
                        lastResponse = null;

                    }

                } catch (error) {

                    lastResponse = null
                    await TimingUtil.waitSeconds(1);

                }

            }

        } catch (error) {

            response.success = false;
            console.log('GET COLLECTIONS DETAILS FAILED\nERROR: ', error);

        }

        console.log('Finished get of collection "' + collectionSymbol + '" details at ' + new Date().toISOString());

        return response;

    }

}

export default new MagicEdenService();